import { useRef, useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import PdfHeader from './PdfHeader';
import { EditText, EditTextarea } from 'react-edit-text';
import 'react-edit-text/dist/index.css';
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import { PDFExport } from "@progress/kendo-react-pdf";
import { ToWords } from 'to-words';
export default function PrintView() {
    const navigate = useNavigate();
    let newDate = new Date()
    let month_raw = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    var month
    if (month_raw < 10) { month = "0" + month_raw.toString() } else { month = month_raw.toString() }
    const [invoice, setInvoice] = useState({
        id: "",
        date: "",
        total:"",
        comment: ""
    });
    const [inputs1, setInputs1] = useState(false);
    const [inputs3, setInputs3] = useState(false);
    const [inputs5, setInputs5] = useState(false);
    const [total, setTotal] = useState(0);
    const [finalAmount, setFinalAmount] = useState(0);
    
    const { id} = useParams();
    
    useEffect(() => {
        getCust();
        getHotel();
        getSight();
        getInvoice();
    }, []);
    useEffect(() => {
        var curr = 0;
        if (Array.isArray(inputs3))
            Array.from(inputs3).map((h, key) => {
                curr += parseInt(h.cost, 10);
            });
                
        if (Array.isArray(inputs5))
            Array.from(inputs5).map((h, key) => {
                curr += parseInt(h.cost, 10);
            });

        setTotal(curr);

    }, [inputs1,inputs3,inputs5,invoice]);

    const getCust = async () => {
        const data = { 'userid': localStorage.getItem('userid'), 'custid': id, 'action': 'get_cust_edit' };
        const response = await axios.post(`https://billing.arabianvibesllc.com/api/operations.php`, data);
     
        setInputs1(response.data);
    }
    const getHotel = async () => {
        const data = { 'userid': localStorage.getItem('userid'), 'custid': id, 'action': 'get_hotel_edit' };
        const response = await axios.post(`https://billing.arabianvibesllc.com/api/operations.php`, data);
        
        setInputs3(response.data);
    }
    const getSight = async () => {
        const data = { 'userid': localStorage.getItem('userid'), 'custid': id, 'action': 'get_sight_edit' };
        const response = await axios.post(`https://billing.arabianvibesllc.com/api/operations.php`, data);
        
        setInputs5(response.data);
    }
    const getInvoice = async () => {
        const data = { 'userid': localStorage.getItem('userid'), 'custid': id, 'action': 'get_invoice' };
        const response = await axios.post(`https://billing.arabianvibesllc.com/api/operations.php`, data);
        console.log(response.data);
        setInvoice(response.data);
    }

    const handleInvoiceChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInvoice(values => ({ ...values, [name]: value }));
    }

    const pdfExportComponent = useRef(null);

    const handleExportWithComponent = event => {
        const userdata = { 'userid': localStorage.getItem('userid'), 'custid': id, 'action': 'update_invoice' };
        
        const data = { ...userdata, ...invoice }
        axios.post(`https://billing.arabianvibesllc.com/api/operations.php`, data).then(function (response) {
            console.log(response.data);
            pdfExportComponent.current.save();
        });
        
    };
    const toWords = new ToWords({
        converterOptions: {
            currency: false,
            ignoreDecimal: false,
            ignoreZeroCurrency: false,
            doNotAddOnly: false
        }
    });

    return (

        <div>
            <PDFExport
                scale={0.6}
                paperSize="A4"
                margin="1cm" fileName={inputs1.c_name} ref={pdfExportComponent}>
            <div style={{ 'alignContent': 'center' }}>
                <h2>INVOICE</h2>
            </div>
            
            <PdfHeader />
            <div>
                <MDBContainer>
                    <MDBRow between>
                        <MDBCol>
                            <div style={{ 'text-align': 'left' }}>
                                <h3>Bill To:</h3>
                                Name: {inputs1.c_name}
                                <br></br>
                                Contact: {inputs1.c_email},{inputs1.c_phone}
                                <br></br>
                                GSTIN:  {inputs1.gst}
                            </div>
                        </MDBCol>
                        <MDBCol>
                                <div style={{ 'text-align': 'right' }}>
                                    <b>Invoice No: AV{year}/{month}/{invoice.id}</b>
                                    <br></br>
                                    Currency:  {inputs1.currency}
                            </div>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
                <br></br>
                <div>
                    <table className="table table-striped table-bordered" style={{ 'width': 'max-content', 'min-width': '100%' }}>
                        <thead>
                            <tr>
                                <th>Description</th>
                                <th>Quantity</th>
                                <th>Cost</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Array.isArray(inputs5) ? inputs5.map((s, key) =>
                                <tr key={key}>
                                    <td>{s.title}</td>
                                    <td>{s.count}</td>
                                    <td>{s.cost}</td>
                                </tr>
                                ) : null}
                            {Array.isArray(inputs3) ? inputs3.map((h, key) =>
                                <tr key={key}>
                                    <td>{h.h_name},{h.room_type},{h.meal_plan}</td>
                                    <td>{h.room_count}</td>
                                    <td>{h.cost}</td>
                                </tr>
                            ) : null}
                            <tr>
                                <td>Final Amount To Be Paid</td>
                                <td></td>
                                <td>{total}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                        <EditText value={"Amount in Words:"+inputs1.currency+" "+toWords.convert(total)+" Only "}/>
                </div>

                <div>
                        <MDBContainer >
                            <MDBRow between>
                                <MDBCol>
                                    <div style={{ 'text-align': 'left' }}>
                                        <h4>Payment To:</h4>
                                        Name: Arabian Vibes Tourism LLC
                                        <br></br>
                                        Account No: 9251176899
                                        <br></br>
                                        IBAN: AE970860000009251176899
                                        <br></br>
                                        BIC: WIOBAEADXXX
                                        <br></br>
                                        Bank Name: WIO Bank
                                    </div>
                                </MDBCol>
                                <MDBCol>
                                    <div style={{ 'text-align': 'left' }}>
                                        *Note:This is a Computer Generated Receipt and Does not Require Signature.
                                        <br></br>
                                        <EditText value={invoice.comment} name="comment" onChange={handleInvoiceChange} />
                                    </div>
                                    <div style={{ 'text-align': 'right' }}>
                                        Authorised By: <b>{localStorage.getItem('user')}</b>
                                    </div>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                </div>
            </div>
            </PDFExport>
            <div>
                <button className="btn btn-sm btn-danger" onClick={handleExportWithComponent}>
                    SAVE AS PDF FILE
                </button>
            </div>
        </div>
    )        
    
}