import React from 'react';
import logo from './logo-ab.png';
import 'bootstrap/dist/css/bootstrap.css';
import { EditText } from 'react-edit-text';
export default function PdfHeader() {
    let newDate = new Date()
    let date_raw = newDate.getDate();
    let month_raw = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    var date, month

    if (date_raw < 10) { date = "0" + date_raw.toString() } else { date = date_raw.toString() }
    if (month_raw < 10) { month = "0" + month_raw.toString() } else { month = month_raw.toString() }

        return (
            <div>
                <header>
                    <nav className="navbar ">
                        <div className="navbar-header">
                            <h1 className="logo">
                                <img src={logo} height="150" width="120" style={{ paddingLeft:'10px'}} alt="Arabian Vibes Tourism LLC" />
                            </h1>
                        </div>
                        <div>
                        Office No.75 Arabian Square Buisness Centre <br></br> 4th Floor Al Fahidi heights Al Fahidi St,Dubai 122002<br></br> Contact:+971-552763147,+91-9871163568,trilok@arabianvibesllc.com
 <br></br> +91-9599395089,vineet@arabianvibesllc.com<br></br> Tax Registration Number:104120051800003
                        </div>
                        <div>
                            Date:{date}/{month}/{year}
                        </div>
                    </nav>
                </header>
            </div>
        );
}
