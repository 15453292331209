import { useState} from 'react';
import { MDBBtn, MDBContainer, MDBRow, MDBCol, MDBInput } from 'mdb-react-ui-kit';
import logo from './logo.png';
import sha256 from 'crypto-js/sha256';
import Base64 from 'crypto-js/enc-base64';
const Login = () => {
    
    const [errMsg, setErrMsg] = useState(false);
    const [formData, setFormData] = useState({
        userid: '',
        password: ''
    });
    const onChangeInput = (e) => {
        var value = e.target.value;
        if (e.target.name == 'password') {
            value = Base64.stringify(sha256(e.target.value));
        }
        setFormData({
            ...formData,
            [e.target.name]: value
        })
    }

    const submitForm = async (e) => {
        e.preventDefault();

        if (!Object.values(formData).every(val => val.trim() !== '')) {
            setErrMsg('Please Fill in all Required Fields!');
            return;
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formData)
        };
        fetch('https://billing.arabianvibesllc.com/api/login.php', requestOptions)
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();

                // check for error response
                if (!response.ok) {
                    // get error message from body or default to response status
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }

                localStorage.setItem('loginToken', data.token);
                localStorage.setItem('userid', data.userid);
                localStorage.setItem('user', data.user);
                document.location.reload();
            })
            .catch(error => {
                setErrMsg(error);
            });
    }
    
    return (

        <MDBContainer className="my-5 gradient-form">

            <MDBRow>

                <MDBCol col='6' className="mb-5">
                    <div className="d-flex flex-column ms-5">

                        <div className="text-center">
                            <img src={logo}
                                style={{ width: '185px' }} alt="logo" />
                        </div>

                        <p>Please login to your account</p>


                        <MDBInput name='userid' wrapperClass='mb-4' placeholder='Your UserId' id='userid' type='userid' onChange={onChangeInput} />
                        <MDBInput name='password' wrapperClass='mb-4' placeholder='Your Password' id='password' type='password' onChange={onChangeInput} />
                            {errMsg && <div className="err-msg">{errMsg}</div>}

                        <div className="text-center pt-1 mb-5 pb-1">
                            <MDBBtn className="mb-4 w-100" onClick={submitForm} >Sign in</MDBBtn>
                        </div>
                       
                    </div>

                </MDBCol>

                <MDBCol col='6' className="mb-5">
                    <div className="d-flex flex-column  justify-content-center gradient-custom-2 h-100 mb-4">

                        <div className="text-black px-3 py-4 p-md-5 mx-md-4">
                            <h4 class="mb-4">We are more than just a company</h4>
                            <p class="small mb-0">
                                Arabian Vibes is a young and innovative Travel Marketing & Representation company that was set up to help your business grow successfully and ensure that your vision, values and targets are achieved.
                                We provide better experience by combining the use of technology, creativity, insights and analytical expertise. Our true differentiator from other travel marketing companies and consultants is that we provide both strategic consulting as well as tactical assistance to ensure that your company's sales & visibility needs are never left lingering.
                            </p>
                        </div>

                    </div>

                </MDBCol>

            </MDBRow>

        </MDBContainer>


    )
}

export default Login;
