import { useRef, useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import ItinernaryHeader from './ItinernaryHeader';
import { EditText, EditTextarea } from 'react-edit-text';
import 'react-edit-text/dist/index.css';
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import { PDFExport } from "@progress/kendo-react-pdf";
import { ToWords } from 'to-words';
export default function InvoiceView() {

    const [inputs1, setInputs1] = useState(false);
    const [inputs3, setInputs3] = useState(false);
    const [inputs4, setInputs4] = useState(false);
    const [inputs7, setInputs7] = useState(false);
    const [itinernary, setItinernary] = useState({
        inclusion: "",
        exclusion: "",
        comments: "",
        date: "",
        customer: "",
        passengers: "",
        touragent: "",
        status:"",
        iti_id: "",
        c_id: ""
    });
    const { id } = useParams();


    useEffect(() => {
        getCust();
        getHotel();
        getPickup();
        getActivity();
        getItinernary();
    }, []);

    const getCust = async () => {
        const data = { 'userid': localStorage.getItem('userid'), 'custid': id, 'action': 'get_cust_edit' };
        const response = await axios.post(`https://billing.arabianvibesllc.com/api/operations.php`, data);

        setInputs1(response.data);
    }
    const getHotel = async () => {
        const data = { 'userid': localStorage.getItem('userid'), 'custid': id, 'action': 'get_hotel_edit' };
        const response = await axios.post(`https://billing.arabianvibesllc.com/api/operations.php`, data);

        setInputs3(response.data);
    }
    const getPickup = async () => {
        const data = { 'userid': localStorage.getItem('userid'), 'custid': id, 'action': 'get_pickup_edit' };
        const response = await axios.post(`https://billing.arabianvibesllc.com/api/operations.php`, data);

        setInputs4(response.data);
    }
    const getActivity = async () => {
        const data = { 'userid': localStorage.getItem('userid'), 'custid': id, 'action': 'get_activity_edit' };
        const response = await axios.post(`https://billing.arabianvibesllc.com/api/operations.php`, data);

        setInputs7(response.data);
    }
    const getItinernary = async () => {
        const data = { 'userid': localStorage.getItem('userid'), 'custid': id, 'action': 'get_itinernary' };
        const response = await axios.post(`https://billing.arabianvibesllc.com/api/operations.php`, data);
        console.log(response.data);
        setItinernary(response.data);
    }
    const pdfExportComponent = useRef(null);

    const handleExportWithComponent = event => {
        const userdata = { 'userid': localStorage.getItem('userid'), 'custid': id, 'action': 'update_itinernary' };

        const data = { ...userdata, ...itinernary }
        axios.post(`https://billing.arabianvibesllc.com/api/operations.php`, data).then(function (response) {
            console.log(response.data);
            pdfExportComponent.current.save();
        });
    };

    const handleChangeService = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setItinernary(values => ({ ...values, [name]: value }));
    }

    return (

        <div>
            <PDFExport
                scale={0.6}
                paperSize="A4"
                margin="1cm" fileName={inputs1.c_name+"_Itinernary"} ref={pdfExportComponent}>

                <ItinernaryHeader />
                <div>
                    <MDBContainer style={{ 'text-align': 'left' }}>
                        <MDBRow >
                            <MDBCol size="2">
                                <b>Customer:</b>
                            </MDBCol>
                            <MDBCol size="8">
                                <EditText value={itinernary.customer} type="text" name='customer' onChange={handleChangeService} />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow >
                            <MDBCol size="2">
                                <b>Passengers:</b>
                            </MDBCol>
                            <MDBCol size="8">
                                <EditText value={itinernary.passengers} type="text" name='passengers' onChange={handleChangeService} />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow >
                            <MDBCol size="2">
                                <b>Tour Agent:</b>
                            </MDBCol>
                            <MDBCol size="8">
                                <EditText value={itinernary.touragent} type="text" name='touragent' onChange={handleChangeService} />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow >
                            <MDBCol size="2">
                                <b>Booking Status:</b>
                            </MDBCol>
                            <MDBCol size="8">
                                <EditText value={itinernary.status} type="text" name='status' onChange={handleChangeService} />
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                    <br></br>
                    <div>
                        {Array.isArray(inputs3) ? <div>
                            <div style={{ 'text-align':'left'}}><p><h3>Hotel Details</h3></p></div>
                            <table className="table table-striped table-bordered" style={{ 'width': 'max-content', 'min-width': '100%' }}>
                                <thead>
                                    <tr>
                                        <th>Hotel</th>
                                        <th>Check-In</th>
                                        <th>Check-Out</th>
                                        <th>Room Type</th>
                                        <th>Meal Plan</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {inputs3.map((h, key) =>
                                        <tr key={key}>
                                            <td>{h.h_name}</td>
                                            <td>{h.checkin}</td>
                                            <td>{h.checkout}</td>
                                            <td>{h.room_type}</td>
                                            <td>{h.meal_plan}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div> : null}
                        {Array.isArray(inputs4) ? <div>
                            <div style={{ 'text-align': 'left' }}><p><h3>Pick & Drop Details</h3></p></div>
                            <table className="table table-striped table-bordered" style={{ 'width': 'max-content', 'min-width': '100%' }}>
                                <thead>
                                    <tr>
                                        <th>PickUp</th>
                                        <th>DropTo</th>
                                        <th>Date</th>
                                        <th>Time</th>
                                        <th>Vehicle</th>
                                        <th>Details</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {inputs4.sort((x,y)=>x.date>y.date?1:-1).map((p, key) =>
                                        <tr key={key}>
                                            <td>{p.pickup}</td>
                                            <td>{p.dropto}</td>
                                            <td>{p.date}</td>
                                            <td>{p.time}</td>
                                            <td>{p.vehicle}</td>
                                            <td>{p.details}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div> : null}
                        {Array.isArray(inputs7) ? <div>
                            <div style={{ 'text-align': 'left' }}><p><h3>Sightseeing Activity Details</h3></p></div>
                            <table className="table table-striped table-bordered" style={{ 'width': 'max-content', 'min-width': '100%' }}>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Pickup Time</th>
                                        <th>Activity</th>
                                        <th>Drop Time</th>
                                        <th>Transfer Type</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {inputs7.sort((x,y)=>x.date>y.date?1:-1).map((a, key) =>
                                        <tr key={key}>
                                            <td>{a.date}</td>
                                            <td>{a.pickuptime}</td>
                                            <td>{a.details}</td>
                                            <td>{a.droptime}</td>
                                            <td>{a.transfer}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div> : null}
                    </div>
                    <div style={{ 'text-align': 'left' }}><p><h3>Terms & Conditions</h3></p></div>
                    <MDBContainer style={{ 'text-align': 'left' }}>
                            <div>
                                <MDBRow>
                                    <MDBCol size="3">
                                        <b>Inclusions: </b>
                                    </MDBCol>
                                    <MDBCol size="9">
                                        <EditTextarea value={itinernary.inclusion} placeholder="Enter Inclusions" rows="auto" style={{ 'display': 'grid', 'height': 'fit-content', 'text-align': 'left', 'line-height': '15px' }} type="text" name='inclusion' onChange={handleChangeService} />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol size="3">
                                        <b>Exclusions: </b>
                                    </MDBCol>
                                    <MDBCol size="9">
                                    <EditTextarea value={itinernary.exclusion} placeholder="Enter Exclusions" rows="auto" style={{ 'display': 'grid', 'height': 'fit-content', 'text-align': 'left', 'line-height': '15px' }} type="text" name='exclusion' onChange={handleChangeService} />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow >
                                    <MDBCol size="3">
                                        <b>Additional Details: </b>
                                    </MDBCol>
                                    <MDBCol size="9">
                                        <EditTextarea value={itinernary.comment} placeholder="Enter Any Comments" rows="auto" style={{ 'display': 'grid', 'height': 'fit-content', 'text-align': 'left', 'line-height': '15px' }} type="text" name='comment' onChange={handleChangeService} />
                                    </MDBCol>
                                </MDBRow>
                            </div>
                    </MDBContainer>
                </div>
            </PDFExport>
            <div>
                <button className="btn btn-sm btn-danger" onClick={handleExportWithComponent}>
                    SAVE AS PDF FILE
                </button>
            </div>
        </div>
    )
}