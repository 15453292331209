import React, { Component } from 'react';
import logo from './logo-ab.png';
import 'bootstrap/dist/css/bootstrap.css';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
class Header extends Component {
    logout = () => {
        localStorage.removeItem('loginToken');
        document.location.reload();
    }
    
    render() {
        return (

            
            <div>
                <header>
                    <nav className="navbar ">
                        <div className="navbar-header">
                            <h1 className="logo">
                                <a href="https://billing.arabianvibesllc.com/" className="navbar-brand" title="Arabian Vibes Tourism LLC" rel="home">
                                    <img  src={logo} height="80" alt="Arabian Vibes Tourism LLC" />	</a>
                            </h1>
                        </div>
                        <div>
                            
                        Office No.75 Arabian Square Buisness Centre <br></br> 4th Floor Al Fahidi heights Al Fahidi St,Dubai 122002<br></br> Contact:+971-552763147,+91-9871163568,trilok@arabianvibesllc.com
 <br></br> +91-9599395089,vineet@arabianvibesllc.com
                        </div>
                        <ul className="nav navbar-nav navbar-right mr-5">
                            <li><PersonIcon />{localStorage.getItem('user')}</li>
                            <br></br>
                            <li><button onClick={this.logout}><LogoutIcon/> Logout</button></li>
                        </ul>
                        
                </nav>
                </header>
            </div>
        );
    }
}
export default Header;